import React from "react";


import { UserService } from "../Services/UserService";
import { MdDashboard, MdOutlineInventory, MdOutlineBorderColor, MdLocalGroceryStore, MdAssuredWorkload, MdSupervisorAccount, MdOutlineInventory2, MdOutlineDisplaySettings, MdAccountCircle, MdFactCheck, MdGroup, MdLibraryBooks } from 'react-icons/md';
import { GrUserWorker } from 'react-icons/gr';
import userImage from "../Assets/Images/profile.png";
import { FaBars } from "react-icons/fa6";
import logo from "../Assets/Images/cimmple.png";
import { ToastContainer, toast } from "react-toastify";
import { Notificationslideout } from "../../Modules/NotificationSlideout";
import { resolve, reject } from "q";
import {
  FaRectangleList,
  FaCartShopping,
  FaBriefcase,
  FaUserGear,
  FaGear,
  FaUserGroup,
  FaSquare, FaBook,FaHelmetSafety ,FaPeopleCarryBox  ,FaMoneyCheckDollar ,FaUbuntu ,FaMap  
} from "react-icons/fa6";
import { IoGrid } from "react-icons/io5";
import { IoLibrary } from "react-icons/io5";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';



export class Header extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.getroledata = this.getroledata.bind(this);
    this.state = {
      menuItems: [],
      showNotifications: false
    };
  };


  componentDidMount() {
    this.getroledata();
  }


  getroledata() {
 
    let request: any = {};
    let MenuItems: any = [];


    let storage = JSON.parse(localStorage.getItem("storage")!);

    let role = storage === null ? 0 : (storage.rolId as any);
    let tenantID = storage === null ? 0 : (storage.tenantID as any);



    let userName = storage === null ? 0 : (storage.userName as any);

    request.RoleId = role;
    request.tenantid = tenantID;
    UserService.GetRoleData(request)
      .then(async (result: any[] | null) => {

        if (result != null) {

          result.forEach((element) => {
            let item: any = {};
            item.title = element.title;
            item.link = element.link;
            if(element.subitems.length>0)
              {
            item.subItems = element.subitems;
              }
            if (item.title == "Orders") {
              item.icon = FaRectangleList;
            }
            if (item.title == "Purchasing") {
              item.icon = FaCartShopping;
            }
            if (item.title == "Jobs") {
              item.icon = FaHelmetSafety;
            }
            if (item.title == "Accounts") {
              item.icon = FaMoneyCheckDollar ;
            }
            if (item.title == "Setup") {
              item.icon = FaGear;
            }
            if (item.title == "Library") {
              item.icon = IoLibrary;
            }
            if (item.title == "Administration") {
              item.icon = FaUserGroup;
            }
            if (item.title == "Dashboard") {
              item.icon = IoGrid;
            }
            if (item.title == "Overview") {
              item.icon = FaMap ;
            }
            if (item.title == "Reports") {
              item.icon = FaBook;
            }
            if (item.title == "Quality") {
              item.icon = FaUbuntu;
            }
            MenuItems.push(item);

          });

debugger;

 {MenuItems.map((item, i) =>
  item.subItems ? (
    <div className="accordion-item border-0" key={i}>
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed p-2 rounded"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${i}`}
        >
          <item.icon className="me-3 menu-icon my-1" size={20} />
          <span>{item.title}</span>
        </button>
      </h2>
      <div
        id={`collapse-${i}`}
        className="accordion-collapse collapse submenu"
        data-bs-parent="#menu-accordion"
      >
        <div className="accordion-body p-0">
          <nav className="nav nav-pills flex-column">
            {item.subItems.map((subItem, j) => (
              <NavLink className={`flex-fill nav-link`} to={subItem.link} key={i + "-" + j}>
                <FaSquare className="me-3 my-1" size={20} style={{ color: "transparent" }} />
                <span className="small">{subItem.title}</span>
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </div>
  ) : (
    <NavLink className="flex-fill nav-link p-2" to={item.link} key={i}>
      <item.icon className="me-3 menu-icon my-1" size={20} />
      <span className="fs-6">{item.title}</span>
    </NavLink>
  )
);};
          this.setState({ menuItems: MenuItems }, () => {

          });
        }
        resolve();
      })
      .catch((err: any) => {
        toast.error(`Server Error, ${err}`);
        window.location.replace("/logout");
        reject();
      });



  }




  render() {
    let storage = JSON.parse(localStorage.getItem("storage")!);

    let userName = storage === null ? 0 : (storage.userName as any);
if(userName=="" || userName==0)
  {
    //window.location.replace("/logout");
   }

    return (
      <>
        <header>
        <div className="container-fluid py-1 bg-white d-flex align-items-center shadow">
        
            <button
              className="btn btn-light btn-lg p-2 lh-1 d-lg-none me-2"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#sidebar"
              aria-controls="sidebar"
            >
              <FaBars />
            </button>
            <h2 className="m-0 me-auto">

              {/* <img src={logo} alt="cimmple"  ></img> */}
              <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="200" height="58" viewBox="0 0 2000.000000 580.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,580.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M3550 4964 c-114 -15 -177 -27 -250 -48 -30 -8 -73 -20 -95 -26 -22
-6 -49 -15 -60 -20 -11 -4 -56 -23 -100 -40 -358 -143 -682 -412 -910 -756
-128 -193 -241 -460 -280 -664 -9 -47 -21 -101 -26 -120 -6 -19 -12 -141 -14
-270 -2 -170 -1 -202 4 -115 9 138 26 284 41 335 54 190 96 302 161 425 141
270 377 526 629 681 81 51 249 134 315 158 28 10 59 21 70 26 11 5 47 16 80
25 33 9 71 21 85 26 122 42 499 53 715 21 64 -10 99 -18 220 -52 76 -21 180
-64 280 -115 102 -51 293 -173 317 -202 11 -14 -2 -30 -101 -124 -62 -60 -119
-109 -127 -109 -7 0 -43 18 -79 40 -36 22 -67 40 -70 40 -2 0 -28 11 -57 24
-29 13 -80 32 -113 41 -33 9 -70 21 -83 26 -13 5 -83 17 -157 26 -126 15 -144
15 -270 0 -74 -9 -144 -21 -157 -26 -13 -5 -48 -16 -78 -26 -56 -17 -69 -23
-175 -70 -255 -115 -490 -374 -606 -667 -61 -155 -87 -365 -69 -554 12 -128
13 -131 60 -277 29 -91 66 -171 119 -257 11 -19 21 -39 21 -44 0 -6 -122 -130
-271 -278 -219 -216 -269 -271 -264 -287 7 -23 169 -191 259 -269 68 -59 213
-157 339 -230 92 -52 315 -145 407 -169 137 -35 180 -45 245 -54 105 -15 481
-23 540 -11 28 6 93 18 145 27 103 17 109 19 250 66 103 33 126 43 240 97 532
252 936 748 1074 1317 10 39 21 88 27 110 19 80 31 213 34 375 4 173 -2 157
-24 -65 -19 -194 -72 -375 -166 -570 -48 -99 -142 -255 -191 -315 -116 -142
-207 -231 -344 -336 -111 -85 -355 -224 -394 -224 -7 0 -16 -3 -20 -7 -10 -11
-141 -57 -206 -73 -165 -41 -247 -50 -455 -50 -115 -1 -225 4 -243 9 -18 5
-36 10 -40 10 -23 0 -111 14 -137 21 -16 5 -70 21 -120 36 -108 32 -160 53
-284 116 -92 47 -226 132 -286 182 l-30 25 105 106 c58 59 112 110 120 115 10
6 36 -4 80 -31 111 -67 248 -119 420 -160 70 -17 335 -17 410 0 226 52 389
122 538 233 l83 62 -155 155 -155 155 -43 -32 c-139 -106 -337 -160 -532 -148
-89 6 -110 11 -216 48 -213 74 -413 288 -474 508 -10 36 -22 78 -27 94 -14 47
-10 229 6 301 71 305 281 524 580 606 164 45 367 25 535 -52 124 -58 257 -176
322 -287 l28 -47 439 439 440 439 -77 85 c-67 74 -125 129 -247 231 -107 90
-364 238 -515 296 -25 10 -54 21 -65 25 -11 5 -47 16 -80 25 -33 9 -71 21 -85
26 -147 50 -542 73 -760 43z"/>
<path d="M9519 4142 c-129 -67 -158 -238 -57 -341 47 -48 99 -71 160 -71 143
1 250 130 219 263 -34 150 -186 220 -322 149z"/>
<path d="M17400 4111 c-91 -21 -178 -41 -193 -45 l-27 -6 0 -1010 0 -1010 198
2 197 3 3 1053 c1 578 -1 1052 -5 1051 -5 0 -82 -18 -173 -38z"/>
<path d="M8169 4074 c-212 -38 -448 -167 -593 -325 -108 -117 -181 -247 -233
-416 -25 -81 -27 -102 -27 -273 -1 -164 2 -195 22 -270 89 -323 319 -577 633
-698 149 -57 208 -67 404 -67 157 0 191 3 270 24 185 48 361 144 498 271 l69
63 -134 134 c-73 73 -138 133 -145 133 -6 0 -20 -11 -31 -25 -66 -83 -223
-174 -358 -206 -155 -37 -331 -13 -479 64 -79 42 -202 164 -248 247 -75 136
-101 346 -62 495 81 307 347 500 665 482 184 -10 347 -87 477 -226 l43 -45
141 141 142 140 -65 65 c-144 144 -364 254 -582 293 -108 18 -303 18 -407 -1z"/>
<path d="M10765 3556 c-65 -16 -133 -47 -189 -85 l-45 -31 -3 52 -3 53 -197 3
-198 2 0 -755 0 -755 198 2 197 3 3 531 2 532 40 35 c54 50 109 76 180 86 147
20 250 -33 309 -158 l26 -56 3 -487 3 -488 194 0 195 0 0 529 0 529 50 46
c118 106 304 120 408 30 55 -47 88 -105 101 -178 7 -37 11 -231 11 -508 l0
-448 195 0 195 0 0 513 c0 572 -2 592 -71 728 -45 90 -147 189 -239 233 -99
48 -163 59 -295 53 -174 -7 -301 -58 -411 -164 l-51 -49 -55 57 c-65 66 -158
121 -244 143 -74 19 -236 20 -309 2z"/>
<path d="M13410 3563 c-61 -10 -150 -46 -205 -83 l-60 -40 -3 55 -3 55 -194 0
-195 0 0 -755 0 -755 195 0 195 0 0 534 0 534 48 41 c64 55 138 82 228 83 119
0 201 -49 251 -150 l28 -57 5 -490 5 -490 198 -3 198 -2 -2 525 c-2 590 -7
548 76 605 64 44 120 61 200 62 137 0 230 -69 269 -202 14 -46 16 -122 16
-522 l0 -468 195 0 195 0 0 523 c0 570 -1 578 -59 702 -65 139 -201 250 -356
291 -27 8 -95 14 -151 14 -180 0 -322 -52 -438 -161 l-59 -55 -61 60 c-68 68
-153 118 -241 141 -55 14 -212 19 -275 8z"/>
<path d="M16110 3564 c-147 -24 -229 -52 -305 -103 -25 -17 -48 -31 -51 -31
-2 0 -4 27 -4 60 l0 60 -195 0 -195 0 0 -1055 0 -1055 195 0 195 0 0 350 c0
193 3 350 7 350 3 0 25 -11 48 -25 101 -59 221 -88 364 -87 381 1 690 259 756
629 18 101 18 171 0 275 -53 305 -281 546 -579 613 -67 15 -197 25 -236 19z
m160 -363 c155 -59 265 -208 277 -376 13 -184 -80 -344 -246 -423 -64 -31 -88
-37 -161 -40 -47 -2 -111 1 -141 7 -71 15 -165 60 -212 101 l-37 32 0 293 0
294 38 31 c48 40 144 86 207 99 76 15 211 7 275 -18z"/>
<path d="M18457 3560 c-318 -58 -563 -296 -623 -606 -72 -380 114 -729 468
-875 117 -48 210 -62 372 -56 161 6 234 22 351 78 71 33 213 126 203 133 -1 1
-58 53 -125 114 -67 62 -124 112 -128 112 -3 0 -27 -14 -52 -31 -140 -97 -367
-107 -510 -23 -83 49 -170 153 -188 227 l-7 27 557 0 558 0 -6 138 c-6 158
-27 244 -87 367 -90 181 -236 306 -441 375 -72 25 -258 36 -342 20z m256 -340
c72 -27 155 -107 196 -188 17 -34 31 -68 31 -77 0 -13 -44 -15 -366 -15 l-365
0 7 23 c30 98 130 218 210 252 93 39 191 41 287 5z"/>
<path d="M9430 2795 l0 -755 195 0 195 0 0 755 0 755 -195 0 -195 0 0 -755z"/>
</g>
</svg>

            </h2>
           
            {/* <div onClick={() => this.setState({ showNotifications: true })} className="icon-container">
            <FontAwesomeIcon icon={faBell} style={{cursor:'pointer',height:'35px',width:'35px',marginRight:'30px',color:'#0e223f'}}/>
            <div className="notification-badge">5</div>

            </div> */}
            <div className="dropdown">
              <button
                className="d-flex gap-2 align-items-center rounded-3 text-body p-1 pe-2 btn btn-light dropdown-toggle text-start"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img src={userImage} width={40} className="rounded-3" />
                <div className="d-none d-md-block">
                  <h6 className="fw-semibold m-0">{userName}</h6>

                </div>
              </button>
              <ul className="dropdown-menu  dropdown-menu-end">
                <li className="d-md-none">
                  <div className="px-3 text-muted">
                    <h6 className="fw-semibold m-0">{userName}</h6>

                  </div>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <a className="dropdown-item" href="#">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" onClick={() => window.location.replace("/logout")}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <aside>
          <div
            className="offcanvas-lg offcanvas-start bg-dark h-100 d-flex flex-column"
            tabIndex={-1}
            id="sidebar"
            aria-labelledby="sidebarLabel"
            data-bs-theme="dark"
            style={{ maxHeight: "100vh", maxWidth: "300px" }}
          >
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                data-bs-target="#sidebar"
                aria-label="Close"
              ></button>
            </div>
            <div className="py-lg-3 flex-fill overflow-auto align-items-start scrollbar" id="style-3">
              <nav className="nav nav-pills flex-column menu mx-auto flex-fill px-2" style={{ width: 250 }} >
                <div className="accordion" id="menu-accordion">
                  {this.state.menuItems.map((item, i) =>
                    item.subItems ? (
                      <div className="accordion-item border-0" key={i}>
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed p-2 rounded"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse-${i}`}
                          >
                            <item.icon className="me-3 menu-icon my-1" size={20} />
                            <span>{item.title}</span>
                          </button>
                        </h2>
                        <div
                          id={`collapse-${i}`}
                          className="accordion-collapse collapse submenu"
                          data-bs-parent="#menu-accordion"
                        >
                          <div className="accordion-body p-0">
                            <nav className="nav nav-pills flex-column"    data-bs-dismiss="offcanvas"
                data-bs-target="#sidebar">
                              {item.subItems.map((subItem, j) => (
                                <NavLink className={`flex-fill nav-link`} to={subItem.link} key={i + "-" + j}    >
                                  <FaSquare className="me-3 my-1" size={20} style={{ color: "transparent" }} />
                                  <span className="small">{subItem.title}</span>
                                </NavLink>
                              ))}
                            </nav>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NavLink className="flex-fill nav-link p-2" to={item.link} key={i}     >
                        <item.icon className="me-3 menu-icon my-1" size={20} />
                        <span className="fs-6">{item.title}</span>
                      </NavLink>
                    )
                  )}
                </div>
              </nav>
            </div>
          </div>

        </aside>
        {this.state.showNotifications && ( <Notificationslideout closeSlideOut={()=>this.setState({ showNotifications: false })}/> )}
      </>
    );
  }
}
